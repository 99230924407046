import React from 'react';
import SignIn from "./pages/Login/SignIn";
import {Route, Routes} from "react-router";
import {UserAuth} from "./Firebase/auth";
import ProtectedRoute from "./Firebase/ProtectedRoute";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import AboutTheDay from "./pages/AboutTheDay/AboutTheDay";
import Contact from "./pages/Contact/Contact";
import PhotoAlbum from "./pages/PhotoAlbum/PhotoAlbum";
import Footer from "./components/Footer/Footer";
import Header from "./components/header/Header";
import FAQ from "./pages/FAQ/FAQ";
import Album from "./pages/PhotoAlbum/Album";
import Music from "./pages/Music/Music";
import './index.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Profile from "./pages/Profile/Profile";
import WeddingParty from "./pages/WeddingParty/WeddingParty";
import Accommodation from "./pages/Accommodation/Accommodation";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import RSVP from "./pages/RSVP/RSVP";
import Gift from "./pages/Gift/Gift";
import AddUsers from "./pages/Add users/AddUsers";
import FirstDance from "./pages/FirstDance/FirstDance";

function AppRoutes() {
    const {user} = UserAuth();
    return (
        <div>
            {user && <Header/>}
            <Routes>
                <Route path='/signIn' element={<SignIn/>}/>
                <Route path='/signUp' element={
                    <ProtectedRoute>
                        <AddUsers/>
                    </ProtectedRoute>}/>
                <Route path='/' element={
                    <ProtectedRoute>
                        <Home/>
                    </ProtectedRoute>
                }/>
                <Route path='/about' element={
                    <ProtectedRoute>
                        <About/>
                    </ProtectedRoute>
                }/>
                <Route path='/aboutTheDay' element={
                    <ProtectedRoute>
                        <AboutTheDay/>
                    </ProtectedRoute>
                }/>
                <Route path='/contact' element={
                    <ProtectedRoute>
                        <Contact/>
                    </ProtectedRoute>
                }/>
                <Route path='/photoAlbum' element={
                    <ProtectedRoute>
                        <PhotoAlbum/>
                    </ProtectedRoute>
                }/>
                <Route path='/photoAlbum/:albumName' element={
                    <ProtectedRoute>
                        <Album/>
                    </ProtectedRoute>
                }/>
                <Route path='/FAQ' element={
                    <ProtectedRoute>
                        <FAQ/>
                    </ProtectedRoute>
                }/>
                <Route path='/music' element={
                    <ProtectedRoute>
                        <Music/>
                    </ProtectedRoute>
                }/>
                <Route path='/profile' element={
                    <ProtectedRoute>
                        <Profile/>
                    </ProtectedRoute>
                }/>
                <Route path='/weddingParty' element={
                    <ProtectedRoute>
                        <WeddingParty/>
                    </ProtectedRoute>
                }/>
                <Route path='/accommodation' element={
                    <ProtectedRoute>
                        <Accommodation/>
                    </ProtectedRoute>
                }/>
                <Route path='/RSVP' element={
                    <ProtectedRoute>
                        <RSVP/>
                    </ProtectedRoute>
                }/>
                <Route path='/gift' element={
                    <ProtectedRoute>
                        <Gift/>
                    </ProtectedRoute>
                }/>
                <Route path='/firstDance' element={
                    <ProtectedRoute>
                        <FirstDance/>
                    </ProtectedRoute>
                }/>
                <Route path="*" element={<PageNotFound/>}/>
            </Routes>
            {user && <Footer/>}
        </div>
    );
}

export default AppRoutes;
