import React, {createContext, useState} from "react";
import useGeoLocation from "react-ipgeolocation";

const LanguageContext = createContext();

export function LanguageProvider({children}) {
    const location = useGeoLocation();
    const [language, setLanguage] = useState(localStorage.getItem("language") || location.country);

    if(language === undefined && location.country !== undefined) {
        setLanguage(location.country)
    }

    const swapLanguage = (language) => {
        localStorage.setItem('language', language)
        setLanguage(language)
    };

    return (
        <LanguageContext.Provider value={{language, swapLanguage}}>
            {children}
        </LanguageContext.Provider>
    )
}

export default LanguageContext
