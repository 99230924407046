import React, {useContext} from 'react';
import LanguageContext from "../Context/Context";
import GBProfileData from './GBProfileData'
import FRProfileData from './FRProfileData'
import './ProfileData.css'
import {getUsersProfile} from "../../Firebase/useFirestore";
import {UserAuth} from "../../Firebase/auth";
import {Col, Row} from "react-bootstrap";
import ProfileDataTable from "./ProfileDataTable";

const ProfileData = () => {
    const {user} = UserAuth();
    const {language} = useContext(LanguageContext);
    const profile = getUsersProfile('profiles', user.email);
    let profileData = language === 'GB' ? GBProfileData : FRProfileData;
    return (
        <div className="container">
            {profile &&
            <div className="container">
                <Row className="row my-3">
                    <h2>{profile.Name} {profile.Surname}</h2>
                </Row>
                <Row className="row my-3">
                    <Col className='col-lg-3'>
                        <h4>Email : </h4>
                    </Col>
                    <Col className='col-lg-3'>
                        <p>{profile.Email}</p>
                    </Col>
                </Row>
                <Row className="row my-3">
                    <Col className='col-lg-3'>
                        <h4>{profileData.Address} : </h4>
                    </Col>
                    <Col>
                        <p>{profile.Address['Address Line 1']}</p>
                        <p>{profile.Address['Town/City']}</p>
                        <p>{profile.Address['Country/Region']}</p>
                        <p>{profile.Address['Postcode']}</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-lg-4'>
                        <h4>{profileData.RSVP} : </h4>
                    </Col>
                    <Col>
                        {
                            profile['RSVP Received'] ? <h5>Yes</h5> : <h5>No</h5>
                        }
                    </Col>
                </Row>
                <Row>
                    <h2>
                        {profileData.Members} :
                    </h2>
                    <Row>
                        <ProfileDataTable/>
                    </Row>
                </Row>
            </div>
            }
        </div>

    )
        ;

};

export default ProfileData;
