import {useEffect, useState} from 'react';
import {projectFirestore, projectStorage} from "./config";
import {getDownloadURL, ref, uploadBytesResumable} from 'firebase/storage'
import {addDoc, collection, serverTimestamp} from "firebase/firestore";

const useStorage = async (file, album) => {
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);


    useEffect(() => {
        //ref
        const storageRef = ref(projectStorage, file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
        const collectionRef = collection(projectFirestore, album.album);


        uploadTask.on('state_changed', (snap) => {
            let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
            console.log('Upload is ' + percentage + '% done');
            setProgress(percentage);
        }, (err) => {
            setError(err);
        }, async () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                const createdAt = serverTimestamp();
                addDoc(collectionRef, {url, createdAt});
                setUrl(url)
            });

        })
    }, [file]);

    return {progress, url, error}
};

export default useStorage;
