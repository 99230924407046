import React, {useContext} from "react";
import LanguageContext from "../../components/Context/Context";
import GBContact from './GBContact'
import FRContact from './FRContact'
import ContactForm from "../../components/ContactForm/ContactForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";

function Contact() {
    const {language} = useContext(LanguageContext);
    let contact = language === 'GB' ? GBContact : FRContact
    return (
        <div className="contact">
            <div className="container">
                <Row><h1 className="title"><h1 className="font-weight-light">{contact.Title}</h1></h1></Row>
                <Row className="row justify-content-around my-5">
                    <Col className="col-lg-5">
                        <h5>
                            {contact.P1}
                        </h5>
                        <ContactForm/>
                    </Col>
                    <Col className="col-lg-3">
                        <h5>{contact.P2} <br/>
                        <br/>
                            4st Thomas Road <br/>
                            Midsomer Norton <br/>
                            Radstock <br/>
                            BA3 2UT <br/>
                            UK
                        </h5>
                        <Link className='rsvp' to={'/RSVP'}><button>{contact.RSVP}</button></Link>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Contact;
