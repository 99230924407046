import React, {useContext, useState} from "react";
import LanguageContext from "../../components/Context/Context";
import GBProfile from './GBProfile'
import FRProfile from './FRProfile'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PasswordForm from "../../components/PasswordForm/PasswordForm";
import ProfileData from "../../components/ProfileData/ProfileData";

function Profile() {
    const {language} = useContext(LanguageContext);
    let contact = language === 'GB' ? GBProfile : FRProfile;
    const [btnSelected, setBtnSelected] = useState('profile');

    const show = (name, e) => {
        e.preventDefault();
        setBtnSelected(name)
    };

    return (
        <div className="contact">
            <div className="container">
                <Row><h1 className="title font-weight-light">{contact.Title}</h1></Row>
                <Row className="row justify-content-around my-2">
                    <Col className='col-lg-2'>
                        <Row>
                            <button onClick={(e) => show('profile', e)}>Profile</button>
                        </Row>
                        <Row>
                            <button onClick={(e) => show('password', e)}>Change PW</button>
                        </Row>
                    </Col>
                    <Col className="col-lg-8">
                        <h5>
                            {contact.P1}
                        </h5>
                        {btnSelected === 'profile' && <ProfileData/>}
                        {btnSelected === 'password' && <PasswordForm/>}
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Profile;
