import React, {useContext} from "react";
import LanguageContext from "../../components/Context/Context";
import GBWeddingParty from './GBWeddingParty'
import FRWeddingParty from './FRWeddingParty'
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import './WeddingParty.css'

function WeddingParty() {
    const {language} = useContext(LanguageContext);
    let weddingParty = language === 'GB' ? GBWeddingParty : FRWeddingParty;
    return (
        <div className="aboutTheDay">
            <Container>
                <Row><h1 className="title">{weddingParty.H1}</h1></Row>
                <Row>
                    <p>
                        {weddingParty.P1}
                    </p>
                </Row>
                <Row>
                    {Object.keys(weddingParty.BrideParty).map((bridalParty, idx) => (
                        <Col className="col-lg-2 col-md-4 col-sm-6" key={idx}>
                            <div className="card">
                                <img
                                    className={`img-fluid rounded mb-4 mb-lg-0 image ${weddingParty.BrideParty[bridalParty].Name}`}
                                    src={weddingParty.BrideParty[bridalParty].Pic}
                                    alt={weddingParty.BrideParty[bridalParty].Alt}/>
                                <div className="card-body">
                                    <h5 className="card-title">{weddingParty.BrideParty[bridalParty].Name}</h5>
                                    <p className="card-text">{weddingParty.BrideParty[bridalParty].Text}</p>
                                    <footer
                                        className="blockquote-footer">{weddingParty.BrideParty[bridalParty].Role}</footer>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
                <Row><h1 className="title">{weddingParty.H2}</h1></Row>
                <Row>
                    <p>
                        {weddingParty.P2}
                    </p>
                </Row>
                <Row>
                    {Object.keys(weddingParty.Grooms).map((party, idx) => (
                        <Col className="col-lg-2 col-md-4 col-sm-6" key={idx}>
                            <div className="card">
                                <img
                                    className={`img-fluid rounded mb-4 mb-lg-0 image ${weddingParty.Grooms[party].Name}`}
                                    src={weddingParty.Grooms[party].Pic}
                                    alt={weddingParty.Grooms[party].Alt}/>
                                <div className="card-body">
                                    <h5 className="card-title">{weddingParty.Grooms[party].Name}</h5>
                                    <p className="card-text">{weddingParty.Grooms[party].Text}</p>
                                    <footer className="blockquote-footer">{weddingParty.Grooms[party].Role}</footer>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
}

export default WeddingParty;
