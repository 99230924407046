import React, {useContext, useState, useRef} from "react";
import './patchwork.css'
import {useFirestore} from "../../Firebase/useFirestore";
import PatchworkItem from "./patchworkItem";
import PatchworkComments from "./patchworkComments";
import PatchworkCart from "./patchworkCart";
import PatchworkForm from "./PatchworkForm";
import GBPatchwork from "./GBPatchwork";
import FRPatchwork from "./FRPatchwork";
import LanguageContext from "../Context/Context";


function Patchwork() {

    const {language} = useContext(LanguageContext);
    const {docs} = useFirestore('gifts', 'order', 'asc');
    const comments = useFirestore('comments');
    const [cart, setCart] = useState({});
    const [total, setTotal] = useState(0);
    let Patchwork = language === 'GB' ? GBPatchwork : FRPatchwork;

    const ref = useRef(null);
    const fundsClick = (event) => {
        if (cart[event.name]) {
            if (cart[event.name].quantity < cart[event.name].max) {
                setCart(values => ({
                    ...values, [event.name]: {
                        ...values[event.name],
                        quantity: cart[event.name].quantity + 1
                    }
                }))
                setTotal(total + parseInt(event.price))
                ref.current?.scrollIntoView({behavior: 'smooth'});
            }
        } else {
            setCart(values => ({...values, [event.name]: event}))
            setTotal(total + parseInt(event.price))
            ref.current?.scrollIntoView({behavior: 'smooth'});
        }
    };

    const plusClick = (event) => {
        setTotal(total + parseInt(cart[event].price));
        if (cart[event].quantity < cart[event].max) {
            setCart(values => ({
                ...values, [event]: {
                    ...values[event],
                    quantity: cart[event].quantity + 1
                }
            }))
        }
    };

    const minusClick = (event) => {
        setTotal(total - parseInt(cart[event].price));
        if (cart[event].quantity <= 1) {
            let copyOfCart = {...cart};
            delete copyOfCart[event];
            setCart(cart => ({
                ...copyOfCart
            }))
        } else {
            setCart(values => ({
                ...values, [event]: {
                    ...values[event],
                    quantity: cart[event].quantity - 1
                }
            }))
        }
    };

    const clearCart = () => {
        setCart({})
    };

    return (
        <div className="container">
            <div className="row row-cols-1" >
                {docs && docs.map((gift, idx) => (
                    <PatchworkItem key={idx}
                                   gift={gift}
                                   funds={fundsClick}
                    />
                ))}
            </div>
            <div className="funds" ref={ref}>
                {Object.keys(cart).length > 0 ?
                    <div className="row">
                        <div className="col-lg-6">
                            {Object.keys(cart).map((item, idx) => (
                                <PatchworkCart
                                    key={idx}
                                    cart={cart[item]}
                                    plus={plusClick}
                                    minus={minusClick}

                                />
                            ))}
                        </div>
                        <div className="col-lg-6">
                            <h5>{Patchwork.Account} £{total} {Patchwork.Account2}:</h5>
                            <h6>{Patchwork.AccountName} Barnett E & A</h6>
                            <h6>{Patchwork.AccountNumber} 55894011</h6>
                            <h6>{Patchwork.SortCode} 60-14-24</h6>
                        </div>
                        <PatchworkForm
                            cart={cart}
                            clearCart={clearCart}
                        />
                    </div>
                    :
                    <div>
                        <h4>{Patchwork.Fund_Request}</h4>
                    </div>
                }
            </div>
            <h3 className="comments">{Patchwork.Comments}</h3>
            <div>
                {comments.docs && comments.docs.map((comment, idx) => (
                    <PatchworkComments key={idx}
                                       comment={comment}/>
                ))}

            </div>
        </div>
    );
}

export default Patchwork;
