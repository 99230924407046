import React, {useContext} from "react";
import FRFAQ from "./FRFAQ.json";
import GBFAQ from "./GBFAQ.json";
import Accordion from 'react-bootstrap/Accordion';
import './FAQ.css'
import Background from "../../components/background/Background";
import LanguageContext from "../../components/Context/Context";

function FAQ() {
    const {language} = useContext(LanguageContext);
    let questions = language === 'GB' ? GBFAQ : FRFAQ;
    return (
        <div>
            <div className="faq">
                <h1 className="header">{questions[0][0].Title} </h1>
                <Accordion flush>
                    {questions[1].map((question) => (
                        <Accordion.Item eventKey={question.id} key={question.id}>
                            <Accordion.Header>{question.question}</Accordion.Header>
                            <Accordion.Body>{question.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </div>
    );
}

export default FAQ;
