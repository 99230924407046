import React, {useContext} from "react";
import image from '../../Images/404.png'
import LanguageContext from "../../components/Context/Context";
import {Row, Col} from "react-bootstrap";
import GBHome from './GBPageNotFound'
import FRHome from './FRPageNotFound'
import './PageNotFound.css'

function PageNotFound() {
    const {language} = useContext(LanguageContext);
    let error = language === 'GB' ? GBHome : FRHome
    return (
        <div className="container PageNotFound">
            <Row>
                <h1>{error.Title}</h1>
                <p>{error.P1}</p>
            </Row>
            <Row className=" align-items-center my-5">
                <Col className="col-12">
                    <img
                        className="img-fluid rounded mb-4 mb-lg-0"
                        src={image}
                        alt="Error"
                    />
                </Col>

            </Row>
        </div>
    );
}

export default PageNotFound;
