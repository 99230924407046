// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getStorage} from "firebase/storage";
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAaK_uEk6FNTZnDNr9vlYDyooLI1OPgd4s",
    authDomain: "a-and-e-wedding.firebaseapp.com",
    projectId: "a-and-e-wedding",
    storageBucket: "a-and-e-wedding.appspot.com",
    messagingSenderId: "182812621564",
    appId: "1:182812621564:web:a0c001f7f05eba50acf579",
    measurementId: "G-0R6G3QW1MQ"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const projectStorage = getStorage(firebaseApp);
const projectFirestore = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

export {projectStorage, projectFirestore};
