import React, {useEffect} from 'react';
import useStorage from "../../Firebase/useStorage";
import {motion} from "framer-motion";

const ProgressBar = ({files, setFile, album}) => {
    const {url, progress} = useStorage(files, album);

    useEffect(() => {
        if (url) {
            setFile(null);
        }
    }, [url, setFile]);

    return (
        <motion.div className="progress-bar"
                    initial={{width: 0}}
                    animate={{width: progress + '%'}}
        />
    );
};

export default ProgressBar;
