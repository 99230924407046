import React, {useContext, useRef} from "react";
import LanguageContext from "../../components/Context/Context";
import GBFirstDance from './GBFirstDance'
import FRFirstDance from './FRFirstDance'
import './FirstDance.css'
import song1 from './Music/First Dance start V1.mp3'
import song2 from './Music/First Dance start V2.mp3'
import song3 from './Music/First Dance start V3.mp3'
import song4 from './Music/First Dance. V4mp3.mp3'
import song5 from './Music/First Dance. V5.mp3'
import song6 from './Music/First Dance. V6.mp3'
import song7 from './Music/First Dance. V7.mp3'
import song8 from './Music/First Dance. V8.mp3'
import song9 from './Music/First Dance. V9.mp3'
import song10 from './Music/First Dance. V10.mp3'
import song11 from './Music/First Dance. V11.mp3'
import song12 from './Music/First Dance. V12.mp3'
import song13 from './Music/First Dance. V13.mp3'
import song14 from './Music/First Dance. V14.mp3'
import song15 from './Music/Ending.mp3'
import song16 from './Music/First Dance. V15.mp3'
import song17 from './Music/First Dance Final Version.mp3'
import video from './Music/coming-soon.mov'
import {useWavesurfer} from "@wavesurfer/react";

function FirstDance() {
    const {language} = useContext(LanguageContext);
    let firstDance = language === 'GB' ? GBFirstDance : FRFirstDance

    const SongV1 = useRef(null);
    const SongV2 = useRef(null);
    const SongV3 = useRef(null);
    const SongV4 = useRef(null);
    const SongV5 = useRef(null);
    const SongV6 = useRef(null);
    const SongV7 = useRef(null);
    const SongV8 = useRef(null);
    const SongV9 = useRef(null);
    const SongV10 = useRef(null);
    const SongV11 = useRef(null);
    const SongV12 = useRef(null);
    const SongV13 = useRef(null);
    const SongV14 = useRef(null);
    const SongV15 = useRef(null);
    const SongV16 = useRef(null);
    const SongV17 = useRef(null);

    const options = {
        height: 100,
        waveColor: '#ba0000',
        progressColor: '#00004d',
        barWidth: 2,
        barGap: 1,
        barRadius: 2,
        mediaControls: true,
        autoplay: false,
    };

    const V1 = useWavesurfer({
        container: SongV1,
        url: song1,
        ...options
    });

    const V2 = useWavesurfer({
        container: SongV2,
        url: song2,
        ...options
    });

    const V3 = useWavesurfer({
        container: SongV3,
        url: song3,
        ...options
    });

    const V4 = useWavesurfer({
        container: SongV4,
        url: song4,
        ...options
    });

    const V5 = useWavesurfer({
        container: SongV5,
        url: song5,
        ...options
    });

    const V6 = useWavesurfer({
        container: SongV6,
        url: song6,
        ...options
    });

    const V7 = useWavesurfer({
        container: SongV7,
        url: song7,
        ...options
    });

    const V8 = useWavesurfer({
        container: SongV8,
        url: song8,
        ...options
    });

    const V9 = useWavesurfer({
        container: SongV9,
        url: song9,
        ...options
    });

    const V10 = useWavesurfer({
        container: SongV10,
        url: song10,
        ...options
    });

    const V11 = useWavesurfer({
        container: SongV11,
        url: song11,
        ...options
    });

    const V12 = useWavesurfer({
        container: SongV12,
        url: song12,
        ...options
    });

    const V13 = useWavesurfer({
        container: SongV13,
        url: song13,
        ...options
    });

    const V14 = useWavesurfer({
        container: SongV14,
        url: song14,
        ...options
    });

    const V15 = useWavesurfer({
        container: SongV15,
        url: song15,
        ...options
    });

    const V16 = useWavesurfer({
        container: SongV16,
        url: song16,
        ...options
    });

    const V17 = useWavesurfer({
        container: SongV17,
        url: song17,
        ...options
    });


    return (
        <div className="container">
            <h1 className="font-weight-light title">{firstDance.Title}</h1>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <p>{firstDance.v1}</p>
                </div>
                <div className="col-lg-6">
                    <div ref={SongV1}/>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <div ref={SongV2}/>
                </div>
                <div className="col-lg-6">
                    <p>{firstDance.v2}</p>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <p>{firstDance.v3}</p>
                </div>
                <div className="col-lg-6">
                    <div ref={SongV3}/>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <div ref={SongV4}/>
                </div>
                <div className="col-lg-6">
                    <p>{firstDance.v4}</p>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <p>{firstDance.v5}</p>
                </div>
                <div className="col-lg-6">
                    <div ref={SongV5}/>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <div ref={SongV6}/>
                </div>
                <div className="col-lg-6">
                    <p>{firstDance.v6}</p>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <p>{firstDance.v7}</p>
                </div>
                <div className="col-lg-6">
                    <div ref={SongV7}/>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <div ref={SongV8}/>
                </div>
                <div className="col-lg-6">
                    <p>{firstDance.v8}</p>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <p>{firstDance.v9}</p>
                </div>
                <div className="col-lg-6">
                    <div ref={SongV9}/>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <div ref={SongV10}/>
                </div>
                <div className="col-lg-6">
                    <p>{firstDance.v10}</p>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <p>{firstDance.v11}</p>
                </div>
                <div className="col-lg-6">
                    <div ref={SongV11}/>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <div ref={SongV12}/>
                </div>
                <div className="col-lg-6">
                    <p>{firstDance.v12}</p>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <p>{firstDance.v13}</p>
                </div>
                <div className="col-lg-6">
                    <div ref={SongV13}/>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <div ref={SongV14}/>
                </div>
                <div className="col-lg-6">
                    <p>{firstDance.v14}</p>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <p>{firstDance.v15}</p>
                </div>
                <div className="col-lg-6">
                    <div ref={SongV15}/>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <div ref={SongV16}/>
                </div>
                <div className="col-lg-6">
                    <p>{firstDance.v16}</p>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <div className="col-lg-6">
                    <p>{firstDance.v17}</p>
                </div>
                <div className="col-lg-6">
                    <div ref={SongV17}/>
                </div>
            </div>
            <div className="row align-items-center my-5">
                <video
                    src={video}
                    controls
                    playsInline
                    className="video"
                />
            </div>
        </div>
    );
}

export default FirstDance;