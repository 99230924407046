import React, {useContext, useRef, useState} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form, InputGroup} from "react-bootstrap";
import LanguageContext from "../Context/Context";
import GBPassword from './GBPassword'
import FRPassword from './FRPassword'
import './PasswordForm.css'
import PasswordStrengthBar from "react-password-strength-bar/dist";
import {UserAuth} from "../../Firebase/auth";
import Swal from "sweetalert2";

const PasswordForm = () => {
    const {reauthenticate, updatePW} = UserAuth();
    const {language} = useContext(LanguageContext);
    let password = language === 'GB' ? GBPassword : FRPassword;
    const [inputs, setInputs] = useState({});
    const [score, setScore] = useState(0);
    const [focus, setFocus] = useState(false);
    const [boarderColour, setBoarderColour] = useState();
    const form = useRef();


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
        if (focus) {
            if (name === 'NePWCheck') {
                if (inputs.newPW === value) {
                    setBoarderColour('green')
                } else {
                    setBoarderColour('red')
                }
            } else {
                if (inputs.NePWCheck === value) {
                    setBoarderColour('green')
                } else {
                    setBoarderColour('red')
                }
            }

        }
    };
    const onChangeScore = (e) => {
        setScore(e)
    };

    const enableButton = () => {
        return !(inputs.oldPW && inputs.newPW && inputs.newPW.length > 0 && inputs.newPW === inputs.NePWCheck && score >= 2);

    };

    const changePW = (event) => {
        event.preventDefault();
        reauthenticate(inputs.oldPW).then(() => {
            updatePW(inputs.newPW).then(() => {
                Swal.fire({
                    icon: 'success',
                    title: password.success
                })
            }).catch((error) => {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: password.Error,
                    text: error.text,
                })
            });
        }).catch((error) => {
            //TODO Add function to give erro for wrong old password
            console.log(error)
        });
    };

    const pwMacth = () => {
        setFocus(true)
    };

    return (
        <Form ref={form} onSubmit={changePW}>
            <Col className='col-lg-5'>
                <Row>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><span className="icon fa fa-key fa-lg"/></InputGroup.Text>
                        <Form.Control
                            type="password"
                            name="oldPW"
                            value={inputs.oldPW || ""}
                            onChange={handleChange}
                            placeholder={password.oldPW}
                            required/>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><span className="icon fa fa-key fa-lg"/></InputGroup.Text>
                        <Form.Control
                            type="password"
                            name="newPW"
                            value={inputs.newPW || ""}
                            onChange={handleChange}
                            placeholder={password.newPW}
                            required/>
                    </InputGroup>
                    <div className="mb-3">
                        <PasswordStrengthBar password={inputs.newPW} minLength={7} onChangeScore={onChangeScore}/>
                    </div>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" style={{borderColor: boarderColour}}><span
                            className="icon fa fa-key fa-lg"/></InputGroup.Text>
                        <Form.Control
                            onFocus={pwMacth}
                            style={{borderColor: boarderColour}}
                            type="password"
                            name="NePWCheck"
                            value={inputs.NePWCheck || ""}
                            onChange={handleChange}
                            placeholder={password.NePWCheck}
                            required/>
                    </InputGroup>
                </Row>
                <Row className={'justify-content-md-center'}>
                    <Col lg="2" className={'contactButton'}>
                        <button type="submit" value="Send" disabled={enableButton()}>{password.Button}</button>
                    </Col>
                </Row>
            </Col>
        </Form>
    );
};

export default PasswordForm;
