import React, {useContext} from "react";
import './PopUP.css'
import {motion} from "framer-motion";
import {Table} from "react-bootstrap";
import {addDoc, collection, doc, increment, serverTimestamp, updateDoc} from "firebase/firestore";
import {projectFirestore} from "../../Firebase/config";
import {UserAuth} from "../../Firebase/auth";
import LanguageContext from "../Context/Context";
import GBPopUp from "./GBPopUp";
import FRPopUp from "./FRPopUp";


function PopUp({setPopUp, inputs, setInputs, music}) {
    const {language} = useContext(LanguageContext);
    const {user} = UserAuth();

    let popUp = language === 'GB' ? GBPopUp : FRPopUp;

    const handleClick = (e) => {
        if (e.target.classList.contains('backdrop') || e.target.classList.contains('red'))
            setPopUp(null)
    };

    const newSong = () => {
        const createdAt = serverTimestamp();
        const collectionRef = collection(projectFirestore, 'music');
        const doc = {
            createdAt: createdAt,
            Artist: inputs.artist,
            SongTitle: inputs.songTitle,
            Vote: 0,
            Users: {}
        };
        addDoc(collectionRef, doc).then(() => {
            console.log("Document Field has been added successfully");
            setInputs({});
            setPopUp(null)
        })
            .catch(error => {
                console.log(error);
            })
    };

    const upVote = () => {
        const collectionRef = doc(projectFirestore, 'music', music.id);
        const users = "Users." + user.displayName;
        updateDoc(collectionRef, {
            Vote: increment(1),
            [users]: "up"
        });
        setPopUp(null);
        setInputs({})
    };

    return (
        <div>
            <motion.div className="backdrop" onClick={handleClick}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
            >
                <div className="popUp">
                    <div className="modal_content">
                        <h1>{popUp.Title}</h1>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th className="col-lg-2">#</th>
                                <th className="col-lg-5">{popUp.Th1}</th>
                                <th className="col-lg-3">{popUp.Th2}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><strong>{GBPopUp.TD1}</strong></td>
                                <td>{inputs.songTitle}</td>
                                <td>{inputs.artist}</td>
                            </tr>
                            <tr>
                                <td><strong>{GBPopUp.TD2}</strong></td>
                                <td>{music.SongTitle}</td>
                                <td>{music.Artist}</td>
                            </tr>
                            </tbody>
                        </Table>
                        <div className={'buttons'}>
                            <button className={'blue'} onClick={newSong}>{popUp.BTN1}</button>
                            <button className={'green'} onClick={upVote}>{popUp.BTN2}</button>
                            <button className={'red'} onClick={handleClick}>{popUp.BTN3}</button>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}

export default PopUp;