import React, {useContext, useState} from "react";
import LanguageContext from "../../components/Context/Context";
import GBRSVP from './GBRSVP'
import FRRSVP from './FRRSVP'
import Row from "react-bootstrap/Row";
import RSVPForm from "../../components/RSFVForm/RSVPForm";

function RSVP() {
    const {language} = useContext(LanguageContext);
    let rsvp = language === 'GB' ? GBRSVP : FRRSVP;

    return (
        <div className="contact">
            <div className="container">
                <Row><h1 className="title font-weight-light">{rsvp.Title}</h1></Row>
                <Row className="row justify-content-around my-2">
                    <RSVPForm/>
                </Row>
            </div>
        </div>
    );
}

export default RSVP;
