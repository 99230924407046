import React from 'react';
import {Navigate} from 'react-router-dom';
import {UserAuth} from './auth'

const ProtectedRoute = ({children}) => {
    const {user} = UserAuth();
    if (!user) {
        return <Navigate to='/signIn'/>;
    }
    return children;
};

export default ProtectedRoute;
