import React, {useContext, useRef, useState} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form, InputGroup} from "react-bootstrap";
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import LanguageContext from "../Context/Context";
import GBPatchwork from './GBPatchwork'
import FRPatchwork from './FRPatchwork'
import './patchwork.css'
import {addDoc, collection, doc, increment, serverTimestamp, updateDoc} from "firebase/firestore";
import {projectFirestore} from "../../Firebase/config";

const PatchworkForm = ({cart, clearCart}) => {
    const {language} = useContext(LanguageContext);
    let Patchwork = language === 'GB' ? GBPatchwork : FRPatchwork;
    const [inputs, setInputs] = useState({});
    const form = useRef();

    const SERVICE_ID = "service_k0dy9ki";
    const TEMPLATE_ID = Patchwork.TEMPLATE_ID;
    const PUBLIC_KEY = "_rC8NPNs6GjFtiVLx";

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    };

    const sendEmail = (event) => {
        event.preventDefault();
        const message = {
            message: inputs.private,
            gifts: cart
        }
        const email = {
            name: `Gift from ${inputs.name} `,
            message: JSON.stringify(message)
        };
        if (inputs.message) {
            addPublicMessage()
        }
        updateFunds(cart)
        emailjs.send(SERVICE_ID, TEMPLATE_ID, email, PUBLIC_KEY)
            .then((result) => {
                Swal.fire({
                    icon: 'success',
                    title: Patchwork.success
                })
                setInputs("");
            }, (error) => {
                console.log(error.text);
                Swal.fire({
                    icon: 'error',
                    title: Patchwork.Email,
                    text: error.text,
                })
            });
        clearCart()
    };

    const updateFunds = (funds) => {
        for (const [fund, value] of Object.entries(funds)) {
            console.log(funds[fund].id)
            const collectionRef = doc(projectFirestore, 'gifts', funds[fund].id);
            updateDoc(collectionRef, {
                quantity: increment(-funds[fund].quantity)
            })
        }

    }

    const addPublicMessage = () => {
        const createdAt = serverTimestamp();
        const collectionRef = collection(projectFirestore, 'comments');
        const doc = {
            createdAt: createdAt,
            name: inputs.name,
            p: inputs.message,

        };

        addDoc(collectionRef, doc).then(() => {
            setInputs("");
        })
            .catch(error => {
                console.log(error);
            })
    };

    return (
        <Form ref={form} onSubmit={sendEmail}>
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><span className="icon fa fa-user fa-lg"/></InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="name"
                        value={inputs.name || ""}
                        onChange={handleChange}
                        placeholder={Patchwork.Name}
                        required/>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><span className="icon fa fa-envelope fa-lg"/></InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="email"
                        value={inputs.email || ""}
                        onChange={handleChange}
                        placeholder={Patchwork.Email}
                        required/>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><span
                        className="icon-textarea fa fa-paper-plane fa-lg "/></InputGroup.Text>
                    <Form.Control
                        as="textarea" rows={3}
                        name="private"
                        value={inputs.private || ""}
                        onChange={handleChange}
                        placeholder={Patchwork.Private}
                        required/>
                    <InputGroup.Text id="basic-addon1"><span
                        className="icon-textarea fa fa-comments fa-lg "/></InputGroup.Text>
                    <Form.Control
                        as="textarea" rows={3}
                        name="message"
                        value={inputs.message || ""}
                        onChange={handleChange}
                        placeholder={Patchwork.Message}
                    />
                </InputGroup>
            </Row>
            <Row className={'justify-content-md-center'}>
                <Col lg="2" className={'contactButton'}>
                    <button type="submit" value="Send"
                            disabled={Object.keys(cart).length === 0}>{Patchwork.Button}</button>
                </Col>
            </Row>
        </Form>
    );
};

export default PatchworkForm;
