import React, {useContext} from "react";
import LanguageContext from "../../components/Context/Context";
import GBAboutTheDay from './GBAboutTheDay'
import FRAboutTheDay from './FRAboutTheDay'
import CrowcombeCourt from "../../Images/crowcombe-court.jpg";
import Ceremony1 from "../../Images/widen-1680-noupsize.jfif";
import Ceremony2 from "../../Images/widen-1680-noupsize (1).jfif";
import Reception1 from "../../Images/widen-1680-noupsize (2).jfif";
import Reception2 from "../../Images/widen-1680-noupsize (4).jfif";
import Evening1 from "../../Images/widen-1680-noupsize (3).jfif";
import Evening2 from "../../Images/court_27.jpg";
import {Row} from "react-bootstrap";
import './AboutTheDay.css'
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {getUsersProfile} from "../../Firebase/useFirestore";
import {UserAuth} from "../../Firebase/auth";

function AboutTheDay() {
    const {user} = UserAuth();
    const {language} = useContext(LanguageContext);
    let aboutTheDay = language === 'GB' ? GBAboutTheDay : FRAboutTheDay;
    const profile = getUsersProfile('profiles', user.email)
    return (
        <div className="aboutTheDay">
            <Container>
                <Row><h1 className="title">{aboutTheDay.H1}</h1></Row>
                <Row className="my-5 center">
                    <div className="col-lg-7">
                        <p><strong>Crowcombe Court - </strong>{aboutTheDay.P1}</p>
                    </div>
                    <div className="col-lg-3 imageSize">
                        <img
                            className="img-fluid rounded mb-4 mb-lg-0"
                            src={CrowcombeCourt}
                            alt="Crowcombe Court"
                        />
                    </div>
                </Row>
                {profile && !profile.evening ?
                    <div>
                        <Row className={'center'}>
                            <Col lg={2} className={'imageSize'}>
                                <img
                                    className="img-fluid rounded mb-4 mb-lg-0"
                                    src={Ceremony1}
                                    alt="Crowcombe Court"
                                />
                            </Col>
                            <Col>
                                <h2>{aboutTheDay.Ceremony.Title}</h2>
                                <h4>{aboutTheDay.Ceremony.Date}</h4>
                                <h4>{aboutTheDay.Ceremony.Time}</h4>
                                <p>Crowcombe Court, Crowcombe<br/>
                                    Taunton TA4 4AD, United Kingdom<br/>
                                    {aboutTheDay.Ceremony.Text1}<br/>
                                    {aboutTheDay.Ceremony.Text2}<br/>
                                </p>
                            </Col>
                            <Col lg={2} className={'imageSize'}>
                                <img
                                    className="img-fluid rounded mb-4 mb-lg-0"
                                    src={Ceremony2}
                                    alt="Crowcombe Court"
                                />
                            </Col>
                        </Row>
                        <Row className={'center'}>
                            <Col lg={2} className={'imageSize'}>
                                <img
                                    className="img-fluid rounded mb-4 mb-lg-0"
                                    src={Reception1}
                                    alt="Crowcombe Court"
                                />
                            </Col>
                            <Col>
                                <h2>{aboutTheDay.Reception.Title}</h2>
                                <h4>{aboutTheDay.Reception.Date}</h4>
                                <h4>{aboutTheDay.Reception.Time}</h4>
                                <p>Crowcombe Court, Crowcombe<br/>
                                    Taunton TA4 4AD, United Kingdom<br/>
                                    {aboutTheDay.Reception.Text1}<br/>
                                </p>
                            </Col>
                            <Col lg={2} className={'imageSize'}>
                                <img
                                    className="img-fluid rounded mb-4 mb-lg-0"
                                    src={Reception2}
                                    alt="Crowcombe Court"
                                />
                            </Col>
                        </Row>
                    </div>
                    :
                    <div/>
                }
                <Row className={'center'}>
                    <Col lg={2} className={'imageSize'}>
                        <img
                            className="img-fluid rounded mb-4 mb-lg-0"
                            src={Evening1}
                            alt="Crowcombe Court"
                        />
                    </Col>
                    <Col>
                        <h2>{aboutTheDay.Evening.Title}</h2>
                        <h4>{aboutTheDay.Evening.Date}</h4>
                        <h4>{aboutTheDay.Evening.Time}</h4>
                        <p>Crowcombe Court, Crowcombe<br/>
                            Taunton TA4 4AD, United Kingdom<br/>
                            {aboutTheDay.Evening.Text1}<br/>
                            {aboutTheDay.Evening.Text2}<br/>
                        </p>
                    </Col>
                    <Col lg={2} className={'imageSize'}>
                        <img
                            className="img-fluid rounded mb-4 mb-lg-0"
                            src={Evening2}
                            alt="Crowcombe Court"
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default AboutTheDay;
