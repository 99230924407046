import React, {useState} from "react";
import ProgressBar from "../ProgressBar/ProgressBar";

function UploadForm(album) {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);

    const types = ['image/png', 'image/jpeg'];

    const handleChange = (e) => {
        let error = 'na';
        let files = e.target.files
        Object.keys(files).map((key, index) => {
            if (files[index] && types.includes(files[index].type)) {
                setFile(files[index]);
                setError('');
                console.log(files)
            } else {
                error = 'error'
            }
        });
        if (error === 'na') {
            setFile(files);
        } else {
            setFile(null);
            setError('Please select an image file (png or jpg)');
        }
    };
    return (
        <form>
            <label>
                <input
                    type="file"
                    multiple
                    onChange={handleChange}/>
                <span>+</span>
            </label>
            {file && Object.keys(file).map((item, idx) => (
                <div className="output" key={idx}>
                    {error && <div className="error">{error}</div>}
                    {file[idx] && <div>{file[idx].name}</div>}
                    {file[idx] && <ProgressBar files={file[idx]} setFile={setFile} album={album}/>}
                </div>
            ))}
        </form>
    );
}

export default UploadForm;
