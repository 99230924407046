import React, {useContext} from "react";
import LanguageContext from "../../components/Context/Context";
import GBAccommodation from './GBAccommodation'
import FRAccommodation from './FRAccommodation'
import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import './Accommodation.css'

function Accommodation() {
    const {language} = useContext(LanguageContext);
    let accommodation = language === 'GB' ? GBAccommodation : FRAccommodation;
    return (
        <div className="aboutTheDay">
            <Container>
                <p className='mainBooked'>{accommodation.Booked}</p>
                <Row><h1 className="title">{accommodation.H1}</h1></Row>
                <Row><h5 className={'center'}>{accommodation.P1}</h5></Row>

                <Row><h5 className={'center'}><a
                    href='https://app.thebookingfactory.com/crowcombe-court/book/161223-emma-aymeric#/'
                    target="_blank">https://app.thebookingfactory.com/crowcombe-court/book/161223-emma-aymeric#/</a>
                </h5></Row>
                <Row><p className={'center'}>{accommodation.P3}</p></Row>
                <Row><p className={'center'}>{accommodation.P4}</p></Row>
                <Row><p className={'center'}>{accommodation.P6}</p></Row>
                <Row><p className={'center'}>{accommodation.P2}</p></Row>

                <Row><h1 className="title">{accommodation.H2}</h1></Row>
                <Row><h5 className={'center'}>{accommodation.P5}</h5></Row>
                <Row className={'center'}>
                    {Object.keys(accommodation.Local).map((place, idx) => (
                        <Col className="col-12 col-md-6 col-lg-4 accommodation" key={idx}>

                            <h3>
                                {accommodation.Local[place].Name}
                            </h3>
                            <p>
                                {accommodation.Local[place].Phone}
                            </p>
                            <a href={`http://${accommodation.Local[place].Website}`}
                               target="_blank">{accommodation.Local[place].Website}</a>
                            <p>
                                {accommodation.Local[place].Remark}
                            </p>
                            <p className='booked'>{accommodation.Local[place].Booked}</p>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
}

export default Accommodation;
