import React, {useContext, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {UserAuth} from "../../Firebase/auth";
import './SignIn.css';
import Background from "../../components/background/Background";
import LanguageContext from "../../components/Context/Context";
import GBLogin from './GBLogin'
import FRLogin from './FRLogin'
import GBLogo from "../../Images/GB.png";
import FRLogo from "../../Images/FR.png";
import Swal from "sweetalert2";

const SignIn = () => {
    const {language, swapLanguage} = useContext(LanguageContext);
    let login = language === 'GB' ? GBLogin : FRLogin;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const {signIn} = UserAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await signIn(email, password);
            navigate('/')
        } catch (e) {
            setError(e.message);
            Swal.fire({
                icon: 'error',
                title: login.Error,
                text: login.ErrorBody,
            })
        }
    };


    const {user} = UserAuth();
    if (user) {
        navigate('/')
    }

    const handleSelect = async(lang) => {
        try {
            swapLanguage(lang);
            console.log("SignIn " + lang)
        } catch (e) {
            console.log(e)
        }
    };

    const help = () => {
        Swal.fire({
            icon: 'info',
            title: login.Info,
            text: login.InfoBody,
        })
    }

    return (
        <div>
            <div className='container'>
                <div id="landing-header">
                    <h1>{login.Title1}</h1>
                    <h1>{login.Title2}</h1>
                </div>
                <div id="loginContainer">
                    <div className="card signinCard">
                        <div className="card-body">
                            <h2 className="card-title signinTitle">
                                {login.Language} :
                                    <img
                                        alt=""
                                        src={GBLogo}
                                        width="30"
                                        className="d-inline-block align-top"
                                        onClick={() => handleSelect('GB')}
                                    />
                                    <img
                                        alt=""
                                        src={FRLogo}
                                        width="30"
                                        className="d-inline-block align-top"
                                        onClick={() =>handleSelect('FR')}
                                    />
                            </h2>
                            <form onSubmit={handleSubmit}>
                                <div className='flex flex-col py-2'>
                                    <label className='py-2 font-medium signinLable'>{login.Username}</label>
                                    <input onChange={(e) => setEmail(e.target.value + "@AEWedding.com")} className='border p-3'
                                           type='text'/>
                                </div>
                                <div className='flex flex-col py-2'>
                                    <label className='py-2 font-medium signinLable'>{login.Password}</label>
                                    <input onChange={(e) => setPassword(e.target.value)} className='border p-3'
                                           type='password'/>
                                </div>
                                <button className="glow-on-hover">{login.Button}</button>
                            </form>
                            <a className='help' onClick={event => help()}>{login.help}</a>
                        </div>
                    </div>
                </div>
            </div>
            <Background/>
        </div>
    );
};

export default SignIn;
