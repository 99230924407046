import React from 'react';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from "react-router-dom";

import {createRoot} from "react-dom/client";
import AppRoutes from "./AppRoutes";
import {AuthContextProvider} from "./Firebase/auth";
import {LanguageProvider} from "./components/Context/Context";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);


root.render(
    <Router>
        <AuthContextProvider>
            <LanguageProvider>
                <AppRoutes/>
            </LanguageProvider>
        </AuthContextProvider>
    </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
