import {useEffect, useState} from 'react';
import {projectFirestore} from "./config";
import {collection, onSnapshot, orderBy, query} from "firebase/firestore";

export const useFirestore = (collectionName, sort = 'createdAt', direction = 'desc') => {
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        const q = query(collection(projectFirestore, collectionName), orderBy(sort, direction));
        const unsub = onSnapshot(q, (querySnapshot) => {
            let documents = [];
            querySnapshot.forEach((doc) => {
                documents.push({...doc.data(), id: doc.id})
            });
            setDocs(documents);
        });
        return () => unsub();
    }, [collectionName]);

    return {docs};
};

export const getUsersProfile = (collectionName, userEmail) => {
    const {docs} = useFirestore(collectionName)
    let user;
    docs.forEach((doc, index) => {
        if (doc.userEmail === userEmail) {
            user = index
        }
    })
    return docs[user]
};
