import React from "react";
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";
import './albumGrid.css'
import {useFirestore} from "../../Firebase/useFirestore";

function AlbumGrid({albums}) {

    function getImg(albumName) {
        const {docs} = useFirestore(albumName);
        return docs[1] ? docs[1].url : ''
    }

    return (
        <div className="grid">
            {albums && albums.map((album, idx) => (
                <Link to={album.album}
                      state={{
                          album: album.album,
                          name: album.name
                      }}
                      key={idx}
                >
                    <div className={'albumCard'}>
                        <div className={'img-wrap'}>
                            <img className={'albumImg'} src={getImg(album.album)}/>
                        </div>
                        <Card.Body>
                            <Card.Title>{album.name}</Card.Title>
                        </Card.Body>
                    </div>
                </Link>
            ))}
        </div>
    );
}

export default AlbumGrid;
