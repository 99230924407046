import React, {useState} from 'react';
import Papa from "papaparse";
import './AddUsers.css'
import {UserAuth} from "../../Firebase/auth";
import {addDoc, collection, serverTimestamp} from "firebase/firestore";
import {projectFirestore} from "../../Firebase/config";
import {useFirestore} from "../../Firebase/useFirestore";

function AddUsers() {
    const {user} = UserAuth();
    const [parsedData, setParsedData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [values, setValues] = useState([]);
    const {createUser} = UserAuth();

    const changeHandler = (event) => {
        // Passing file data (event.target.files[0]) to parse using Papa.parse
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const rowsArray = [];
                const valuesArray = [];

                console.log(results)
                // Iterating data to get column name and their values
                results.data.map((d) => {
                    rowsArray.push(Object.keys(d));
                    valuesArray.push(Object.values(d));
                });

                // Parsed Data Response in array format
                setParsedData(results.data);

                // Filtered Column Names
                setTableRows(rowsArray[0]);

                // Filtered Values
                setValues(valuesArray);
                upload(results.data)
            },
        });
    };

    const upload = (result) => {
        result.map((data) => {
            signUp(data)
            profile(data)
        })
    };

    const profile = async (data) => {
        const createdAt = serverTimestamp();
        const collectionRef = collection(projectFirestore, 'profiles');
        const doc = {
            Name: data.Name,
            Surname: data.Surname,
            Email: data.Email,
            'RSVP Received': false,
            'evening': true,
            userEmail: data.userEmail,
            createdAt: createdAt,
            Address: {
                'Address Line 1': data['Address Line 1'],
                'Town/City': data['Town/City'],
                'Country/Region': data['Country/Region'],
                'Postcode': data['Postcode']
            },
            Party: getParty(data)
        };
        addDoc(collectionRef, doc).then(() => {
            console.log("Document Field has been added successfully - " + data.Name);
        }).catch(error => {
            console.log(error);
        })
    };

    const getParty = (data) => {
        let party = {};

        for (let i = 0; i < 6; i++) {
            if (data[i + '-Attending'].length > 0) {
                party[i] = {
                    Attending: data[i + '-Attending'],
                    Name: data[i + '-Name'],
                    Surname: data[i + '-Surname']
                }
            }
        }
        return party
    }

    const signUp = async (data) => {
        try {
            await createUser(data.userEmail, data.Password, data.Name);
        } catch (e) {
            console.log(e.message)
        }
    };


    const {docs} = useFirestore('profiles', 'Name');
    const updateDocs = () => {
        /* docs.forEach((user) => {
         const collectionRef = doc(projectFirestore, 'profiles', user.id);
         updateDoc(collectionRef, {
             evening: false
            });
         })
          */
    };

    return (
        <div>
            {/* File Uploader */}
            {user.displayName === 'Aymeric' &&
            <input
                type="file"
                name="file"
                onChange={changeHandler}
                accept=".csv"
                style={{display: "block", margin: "10px auto"}}
            />
            }
            <br/>
            <br/>
            {/* Table */}
            <table>
                <thead>
                <tr>
                    {tableRows.map((rows, index) => {
                        return <th key={index}>{rows}</th>;
                    })}
                </tr>
                </thead>
                <tbody>
                {values.map((value, index) => {
                    return (
                        <tr key={index}>
                            {value.map((val, i) => {
                                return <td key={i}>{val}</td>;
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            {user.displayName === 'Aymeric' &&
            <button onClick={updateDocs}>Doc Update</button>
            }
        </div>
    );
}

export default AddUsers;
