import React, {useContext} from "react";
import './About.css'
import {Col, Container, Row} from "react-bootstrap";
import LanguageContext from "../../components/Context/Context";
import GBAbout from "./GBAbout";
import FRAbout from "./FRAbout";

function About() {
    const {language} = useContext(LanguageContext);
    let about = language === 'GB' ? GBAbout : FRAbout;
    return (
        <div className="about">
            <Container>
                <Row>
                    <Col className="col-lg-6">
                        <Row className="my-5 justify-content-md-center">
                            <Col md={{span: 8, offset: 3}}>
                                <div className='profile'>
                                    <img
                                        className="aymeric"
                                        src='https://firebasestorage.googleapis.com/v0/b/a-and-e-wedding.appspot.com/o/AymericYoung.png?alt=media&token=25b3e580-c6a7-498e-bd62-ea60aa9f65d3'
                                        alt="Disneyland Picture with Stitch"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md={{span: 6, offset: 1}}>
                                <h1 className="font-weight-light">Aymeric</h1>
                                <p>
                                    {about.Aymeric.DOB}<br/>
                                    {about.Aymeric.Born}<br/>
                                    {about.Aymeric.Job}<br/>
                                    {about.Aymeric.Parents}<br/>
                                    {about.Aymeric.Siblins}<br/>
                                    {about.Aymeric.Hobby}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-lg-6">
                        <Row className="my-5">
                            <Col md={{span: 8, offset: 2}}>
                                <div className='profile'>
                                    <img
                                        className="emma"
                                        src='https://firebasestorage.googleapis.com/v0/b/a-and-e-wedding.appspot.com/o/EmmaYoung.png?alt=media&token=a57f77cf-95f2-4c60-ba7a-2c50bcffef42'
                                        alt="Disneyland Picture with Stitch"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{span: 9, offset: 3}}>
                                <h1 className="font-weight-light">Emma</h1>
                                <p>
                                    {about.Emma.DOB}<br/>
                                    {about.Emma.Born}<br/>
                                    {about.Emma.Job}<br/>
                                    {about.Emma.Parents}<br/>
                                    {about.Emma.Siblins}<br/>
                                    {about.Emma.Hobby}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="my-5 justify-content-md-center">
                    <Col xs={5} lg={7}>
                        <h1 className="font-weight-light">{about.LoveTitle}</h1>
                        <p>
                            {about.LoveP1}<br/>
                            {about.LoveP2}<br/>
                            {about.LoveP3}<br/>
                            {about.LoveP4}<br/>
                            {about.LoveP5}<br/>
                            {about.LoveP6}
                        </p>
                    </Col>
                    <Col xs={5} lg={3}>
                        <img
                            className="img-fluid rounded mb-4 mb-lg-0"
                            src='https://firebasestorage.googleapis.com/v0/b/a-and-e-wedding.appspot.com/o/lockdown2.jpg?alt=media&token=115959c6-599f-4d40-8cc4-25c1725e57a5'
                            alt="Lockdown"
                        />
                    </Col>
                </Row>
                <Row className="my-5 justify-content-md-center">
                    <Col xs={5} lg={3}>
                        <img
                            className="img-fluid rounded mb-4 mb-lg-0"
                            src='https://firebasestorage.googleapis.com/v0/b/a-and-e-wedding.appspot.com/o/Lockdown.jpg?alt=media&token=eda69a9a-ebdd-460b-958c-a1ffcc0af71c'
                            alt="First Date"
                            width='150'
                        />
                    </Col>
                    <Col xs={5} lg={7}>
                        <h1 className="font-weight-light">{about.LockDownTitle}</h1>
                        <p>{about.LockDownP}</p>
                    </Col>
                </Row>
                <Row className="my-5 justify-content-md-center">
                    <Col xs={5} lg={7}>
                        <h1 className="font-weight-light">{about.MovingTitle}</h1>
                        <p>{about.MovingP}</p>
                    </Col>
                    <Col xs={5} lg={3}>
                        <img
                            className="img-fluid rounded mb-4 mb-lg-0"
                            src='https://firebasestorage.googleapis.com/v0/b/a-and-e-wedding.appspot.com/o/Moving.jpg?alt=media&token=a2a36715-981f-446d-87b5-6f19d4e73de9'
                            alt="Outside the house"
                        />
                    </Col>
                </Row>
                <Row className="my-5 justify-content-md-center">
                    <Col xs={5} lg={3}>
                        <img
                            className="img-fluid rounded mb-4 mb-lg-0"
                            src='https://firebasestorage.googleapis.com/v0/b/a-and-e-wedding.appspot.com/o/engage.jpg?alt=media&token=1e6154bd-fdf2-438c-842b-f8b950d8abb2'
                            alt="Disneyland Picture with Stitch"
                        />
                    </Col>
                    <Col xs={5} lg={7}>
                        <h1 className="font-weight-light">{about.TripTitle}</h1>
                        <p>{about.TripP}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default About;
