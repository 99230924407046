import React, {useContext} from 'react';
import LanguageContext from "../Context/Context";
import GBProfileData from './GBProfileData'
import FRProfileData from './FRProfileData'
import './ProfileData.css'
import {getUsersProfile} from "../../Firebase/useFirestore";
import {UserAuth} from "../../Firebase/auth";
import {Row, Table} from "react-bootstrap";

const ProfileDataTable = () => {
    const {user} = UserAuth();
    const {language} = useContext(LanguageContext);
    const profile = getUsersProfile('profiles', user.email);
    let profileData = language === 'GB' ? GBProfileData : FRProfileData;
    console.log(profile)
    return (
        <div className="container">
            {profile &&
            <div>
                {
                    profile.evening ? (
                        <Row>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th className="col-lg-2">{profileData.Name}</th>
                                    <th className="col-lg-3">{profileData.Surname}</th>
                                    <th className="col-lg-1">{profileData.Attending}</th>
                                    <th className="col-lg-2">{profileData.Adult}</th>
                                    <th className="col-lg-2">{profileData.Kids}</th>
                                    <th className="col-lg-3">{profileData.Diet}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.keys(profile.Party).map((party) => (
                                        <tr key={party}>
                                            <td>{profile.Party[party].Name}</td>
                                            <td>{profile.Party[party].Surname}</td>
                                            {
                                                profile.Party[party].Attending === '-' ?
                                                    <td>{profile.Party[party].Attending}</td>
                                                    :
                                                    <td>{profile.Party[party].Attending === 'yes' ? profileData.Yes : profileData.No}</td>
                                            }
                                            <td>{profile.Party[party].Adult}</td>
                                            <td>{profile.Party[party].Kid}</td>
                                            <td>{profile.Party[party].Diet}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </Row>) : (
                        <Row>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th className="col-lg-2">{profileData.Name}</th>
                                    <th className="col-lg-3">{profileData.Surname}</th>
                                    <th className="col-lg-1">{profileData.Attending}</th>
                                    <th className="col-lg-3">{profileData.Meal}</th>
                                    <th className="col-lg-3">{profileData.Diet}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.keys(profile.Party).map((party) => (
                                        <tr key={party}>
                                            <td>{profile.Party[party].Name}</td>
                                            <td>{profile.Party[party].Surname}</td>
                                            {
                                                profile.Party[party].Attending === '-' ?
                                                    <td>{profile.Party[party].Attending}</td>
                                                    :
                                                    <td>{profile.Party[party].Attending === 'yes' ? profileData.Yes : profileData.No}</td>
                                            }
                                            {
                                                profile.Party[party].Meal === '-' || profile.Party[party].Meal === undefined ?
                                                    <td>{profileData.notChosen}</td>
                                                    :
                                                    <div>
                                                        {
                                                            profile.Party[party].Kid ?
                                                                <td>{profileData.Kid}</td>
                                                                :
                                                                <td>{profile.Party[party].Meal === 'veg' ? profileData.Veg : profileData.Meat}</td>
                                                        }
                                                    </div>
                                            }
                                            <td>{profile.Party[party].Diet}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </Row>)
                }
            </div>
            }
        </div>
    );
};

export default ProfileDataTable;
