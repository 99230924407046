import React, {useContext, useEffect, useState} from 'react';
import './CountdownTimer.css';
import {getRemainingTimeUntilMsTimestamp} from './CountdownTimerUtils';
import LanguageContext from "../Context/Context";
import GBCounter from './GBCounter'
import FRCounter from './FRCounter'
import {Col, Row} from "react-bootstrap";

const defaultRemainingTime = {
    seconds: '00',
    minutes: '00',
    hours: '00',
    days: '00'
};

const CountdownTimer = ({countdownTimestampMs}) => {
    const {language} = useContext(LanguageContext);
    let counter = language === 'GB' ? GBCounter : FRCounter
    const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateRemainingTime(countdownTimestampMs);
        }, 1000);
        return () => clearInterval(intervalId);
    }, [countdownTimestampMs]);

    function updateRemainingTime(countdown) {
        setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
    }

    return (
        <div className="countdown-timer">
            <Row>
                <Col xs={12} sm={6} lg={4} xl={3}>
                    <span>{remainingTime.days}</span>
                    {remainingTime.days === "01" ? <span>{counter.day}</span> : <span>{counter.days}</span>}
                </Col>
                <Col xs={12} sm={6} lg={4} xl={3}>
                    <span className="two-numbers">{remainingTime.hours}</span>
                    {remainingTime.hours === "01" ? <span>{counter.hour}</span> : <span>{counter.hours}</span>}
                </Col>
                <Col xs={12} sm={6} lg={4} xl={3}>
                    <span className="two-numbers">{remainingTime.minutes}</span>
                    {remainingTime.minutes === "01" ? <span>{counter.minute}</span> : <span>{counter.minutes}</span>}
                </Col>
                <Col xs={12} sm={6} lg={4} xl={3}>
                    <span className="two-numbers">{remainingTime.seconds}</span>
                    {remainingTime.seconds === "01" ? <span>{counter.second}</span> : <span>{counter.seconds}</span>}
                </Col>
            </Row>
        </div>
    );
};

export default CountdownTimer;
