import React, {useContext} from "react";
import LanguageContext from "../../components/Context/Context";
import GBGift from './GBGift'
import FRGift from './FRGift'
import './Gift.css'
import {Row, Col} from "react-bootstrap";
import Patchwork from "../../components/patchwork/patchwork";

function Gift() {
    const {language} = useContext(LanguageContext);
    let gift = language === 'GB' ? GBGift : FRGift;
    return (
        <div className="gift">
            <div className="container">
                <div className="row align-items-center my-5">
                    <Row><h1 className="title font-weight-light">{gift.Title}</h1></Row>
                    <Row className={'center'}>
                        <Col>
                            <p>{gift.P1}</p>
                            <p>{gift.P2}</p>
                        </Col>
                    </Row>
                    <Row className="center Banner">
                        <Col>
                            <h3>{gift.P3}</h3>
                            <p>{gift.P4}</p>
                            <p>{gift.P5}</p>
                        </Col>
                    </Row>
                    <Row className={'center'}>
                        <Patchwork/>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default Gift;
