import React from "react";
import './Footer.css'

function Footer() {
    return (
        <div>
            <footer className="footer py-1 bg-dark fixed-bottom">
                <div className="container">
                    <p className="m-0 text-center text-white">
                        Copyright &copy; Aymeric 2024
                    </p>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
