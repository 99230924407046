import React, {createContext, useContext, useEffect, useState} from 'react';
import {
    createUserWithEmailAndPassword,
    EmailAuthProvider,
    onAuthStateChanged,
    reauthenticateWithCredential,
    signInWithEmailAndPassword,
    signOut,
    updatePassword,
    updateProfile
} from 'firebase/auth';
import {auth} from "./config";

const UserContext = createContext();

export const AuthContextProvider = ({children}) => {
        const [user, setUser] = useState({});
        const createUser = (email, password, username) => {
            return createUserWithEmailAndPassword(auth, email, password)
                .then((newUser) => {
                    updateProfile(newUser.user, {
                        displayName: username,
                        photoURL: "https://example.com/jane-q-user/profile.jpg"
                    }).then(() => {
                    }, function (error) {
                    });
                }, function (error) {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    if (errorCode === 'auth/weak-password') {
                        alert('The password is too weak.');
                    } else {
                        console.error(error);
                    }
                })
        };

        const signIn = (email, password) => {
            return signInWithEmailAndPassword(auth, email, password)
        };

        const logout = () => {
            return signOut(auth)
        };

        const reauthenticate = (pw) => {
            const credential = EmailAuthProvider.credential(
                user.email,
                pw
            );
            return reauthenticateWithCredential(user, credential)
        };

        const updatePW = (newPassword) => {
            return updatePassword(user, newPassword)
        }

        useEffect(() => {
            const unSub = onAuthStateChanged(auth, (currentUser) => {
                setUser(currentUser)
            });
            return () => {
                unSub()
            }
        }, []);

        return (
            <UserContext.Provider value={{createUser, user, logout, signIn, reauthenticate, updatePW}}>
                {children}
            </UserContext.Provider>
        )
    }
;

export const UserAuth = () => {
    return useContext(UserContext);
};
