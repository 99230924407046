import React from "react";
import './patchwork.css'

function PatchworkComments({comment}) {

    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    const colour = '#' + randomColor;
    return (
        <div>
            <div className="row">
                <article className="message">
                    <div className="message__hold">
                        <div className="message__thumbnail">
                            <span className="dot" style={{backgroundColor: colour}}/>
                        </div>
                        <div className="message__content">
                            <h3 className="name">{comment.name}</h3>
                            <p>{comment.p}</p>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    );
}

export default PatchworkComments;
