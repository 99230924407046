import React from 'react';
import './background.css';
import {useFirestore} from "../../Firebase/useFirestore";

const Background = () => {
    const {docs} = useFirestore('background');

    let delay = 0;

    const addImage = (imageUrl) => {
        const imageDelay = delay;
        delay += 10;
        return {
            backgroundImage: `url(${imageUrl})`,
            animationDelay: `${imageDelay}s`
        }
    };

    return (
        <ul className="slideshow">
            {docs && docs.map(doc => (
                <li style={addImage(doc.url)} key={doc.id}/>
            ))}
        </ul>
    );
};

export default Background;
