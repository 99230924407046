import React, {useContext, useRef, useState} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form, InputGroup} from "react-bootstrap";
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import LanguageContext from "../Context/Context";
import GBContact from './GBContact'
import FRContact from './FRContact'
import './ContactForm.css'

const ContactForm = () => {
    const {language} = useContext(LanguageContext);
    let contact = language === 'GB' ? GBContact : FRContact
    const [inputs, setInputs] = useState({});
    const form = useRef();

    const SERVICE_ID = "service_k0dy9ki";
    const TEMPLATE_ID = contact.TEMPLATE_ID;
    const PUBLIC_KEY = "_rC8NPNs6GjFtiVLx";

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    };

    const sendEmail = (event) => {
        event.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
            .then((result) => {
                Swal.fire({
                    icon: 'success',
                    title: contact.success
                })
                setInputs("");
            }, (error) => {
                console.log(error.text);
                Swal.fire({
                    icon: 'error',
                    title: contact.Email,
                    text: error.text,
                })
            });
    };

    return (
        <Form ref={form} onSubmit={sendEmail}>
            <Row>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><span className="icon fa fa-user fa-lg"/></InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="name"
                        value={inputs.name || ""}
                        onChange={handleChange}
                        placeholder={contact.Name}
                        required/>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><span className="icon fa fa-envelope fa-lg"/></InputGroup.Text>
                    <Form.Control
                        type="text"
                        name="email"
                        value={inputs.email || ""}
                        onChange={handleChange}
                        placeholder={contact.Email}
                        required/>
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><span className="icon-textarea fa fa-paper-plane fa-lg "/></InputGroup.Text>
                    <Form.Control
                        as="textarea" rows={3}
                        name="message"
                        value={inputs.message || ""}
                        onChange={handleChange}
                        placeholder={contact.Message}
                        required/>
                </InputGroup>
            </Row>
            <Row className={'justify-content-md-center'}>
                <Col lg="2" className={'contactButton'}>
                    <button type="submit" value="Send">{contact.Button}</button>
                </Col>
            </Row>
        </Form>
    );
};

export default ContactForm;
