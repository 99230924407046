import React from "react";
import './patchwork.css'

function PatchworkCart({cart, plus, minus}) {

    return (
        <div>
            <article className="cart">
                <h6 className="item">{cart.name}</h6>
                <div>
                    <button className="minus item" onClick={() => minus(cart.name)}><i className="fa fa-minus"
                                                                                       aria-hidden="true"/></button>
                    <p className="item">{cart.quantity}</p>
                    {cart.quantity >= cart.max ?
                        < button className="plus item" disabled={true}><i className="fa fa-plus"
                                                                          aria-hidden="true"/></button>
                        :
                        < button className="plus item" onClick={() => plus(cart.name)}><i className="fa fa-plus"
                                                                                          aria-hidden="true"/>
                        </button>

                    }
                    <p className="item">£{cart.price * cart.quantity}</p>
                </div>
            </article>
        </div>
    );
}

export default PatchworkCart;
