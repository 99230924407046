import React, {useContext, useState} from "react";
import UploadForm from "../../components/UploadForm/UploadForm";
import ImageGrid from "../../components/imageGrid/imageGrid";
import Modal from "../../components/Modal/Modal";
import {useFirestore} from "../../Firebase/useFirestore";
import {Link, useLocation} from "react-router-dom";
import './Album.css'
import LanguageContext from "../../components/Context/Context";
import GBAlbum from "./GBAlbum";
import FRAlbum from './FRAlbum'
import {UserAuth} from "../../Firebase/auth";

function Album() {
    const {language} = useContext(LanguageContext);
    const {user} = UserAuth();
    let album = language === 'GB' ? GBAlbum : FRAlbum;
    const location = useLocation();
    const state = location.state;
    const [selectedImage, setSelectedImg] = useState(null);
    const {docs} = useFirestore(state.album);
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-xs-5">
                        <h1 className="album font-weight-light">{state.name}</h1>
                        <Link to={'/photoAlbum'}>
                            <button className={'arrow'}>{album.Button}</button>
                        </Link>
                        {user.displayName === 'Aymeric' && <UploadForm album={state.album}/>}
                        <ImageGrid setSelectedImg={setSelectedImg} docs={docs}/>
                        {selectedImage &&
                        <Modal
                            selectedImage={selectedImage}
                            setSelectedImg={setSelectedImg}
                            docs={docs}
                        />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Album;
