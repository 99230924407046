import React, {useRef} from "react";
import './patchwork.css'

function PatchworkItem({gift, funds}) {

    const fund = {
        id: gift.id,
        name: gift.title,
        max: gift.quantity,
        price: gift.price,
        quantity: 1
    };

    const colSize = (input = 3) => {
        let col = 12
        const windowWidth = useRef(window.innerWidth);
        if (windowWidth.current > 1200) {
            col = input
        } else if (windowWidth.current > 768) {
            col = 4
        } else if (windowWidth.current > 576) {
            col = 6
        }
        return `col-${col} grid-stack-item patchwork-patch__container`
    };

    return (
        <div
            className={colSize(gift.col)}>
            <div className="grid-stack-item-content">
                <div className="patchwork-patch__content patchwork-patch-panel">
                    <h3 className="patchwork-patch-panel__title">{gift.title}</h3>
                    <div className="patchwork-patch-panel__description">{gift.description}</div>
                    {gift.quantity !== 0 && gift.quantity <= 3 &&
                    <div className="patchwork-patch-panel__quantinty">Only {gift.quantity} Left</div>
                    }
                    <div className="patchwork-patch-panel__price ">£{gift.price}</div>
                    {
                        gift.quantity === 0 ? <span className="patchwork-patch-panel__soldout">Sold out</span>
                            :
                            <button type="button"
                                    className="btn btn-primary patchwork-patch-panel__button"
                                    onClick={() => funds(fund)}
                            >Fund </button>
                    }
                </div>
                <div className="patchwork-patch__content patchwork-patch__image"
                     style={{backgroundImage: `url(${gift.image})`}}/>
            </div>
        </div>
    );
}

export default PatchworkItem;
