import React, {useContext} from "react";
import image from './images/249929123_4481323218622374_2918378362094465529_n.jpg'
import image2 from './images/Maple&Fleur_E&A2.jpg'
import CountdownTimer from "../../components/CountdownTimer/CountdownTimer";
import LanguageContext from "../../components/Context/Context";
import GBHome from './GBHome'
import FRHome from './FRHome'
import {Link} from "react-router-dom";
import './Home.css'
import {getUsersProfile} from "../../Firebase/useFirestore";
import {UserAuth} from "../../Firebase/auth";

function Home() {
    const {user} = UserAuth();
    const {language} = useContext(LanguageContext);
    const profile = getUsersProfile('profiles', user.email)
    let home = language === 'GB' ? GBHome : FRHome
    return (
        <div className="home">
            <div className="container">
                <div className="row align-items-center my-5">
                    <div className="col-lg-5">
                        <h2 className="font-weight-light">{home.Thank}</h2>
                        <p>{home.P5}</p>
                    </div>
                    <div className="col-lg-7">
                        <img
                            className="img-fluid rounded mb-4 mb-lg-0"
                            src={image2}
                            alt="Wedding Heart"
                        />
                    </div>
                </div>
                <div className="row align-items-center my-5">
                    <div className="col-lg-7">
                        <img
                            className="img-fluid rounded mb-4 mb-lg-0"
                            src={image}
                            alt="Disneyland Picture with Stitch"
                        />
                    </div>
                    <div className="col-lg-5">
                        <h1 className="font-weight-light">{home.Title}</h1>
                        <p>{home.P1}</p>
                        <p>{home.P2}</p>
                        <p>{home.P3} {profile && profile.Date !== undefined ? profile.Date : (profile && profile.evening ? home.evening : home.Date)}{home.P32}</p>
                        <p>{home.P4}</p>
                        {/*<Link className='rsvp' to={'/RSVP'}><button>{home.RSVP}</button></Link>*/}
                    </div>
                </div>
                {/*<div className="col-lg-12">*/}
                {/*    {profile && profile.evening ?*/}
                {/*        <CountdownTimer countdownTimestampMs={1702753200000}/>*/}
                {/*        :*/}
                {/*        <CountdownTimer countdownTimestampMs={1702733400000}/>*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default Home;
