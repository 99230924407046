import React, {useContext} from "react";
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap'
import {UserAuth} from "../../Firebase/auth";
import {useNavigate} from "react-router-dom";
import logo from '../../Images/A-E Colour30.png'
import GBLogo from '../../Images/GB.png'
import FRLogo from '../../Images/FR.png'
import './Header.css'
import FRHeader from './Header-FR'
import GBHeader from './Header-GB'
import LanguageContext from "../Context/Context";

function Header() {

    const {language, swapLanguage} = useContext(LanguageContext);
    const {user, logout} = UserAuth();
    const navigate = useNavigate();

    let text = language === 'GB' ? GBHeader : FRHeader;
    const handleLogout = async () => {
        try {
            await logout();
            navigate('/signIn')
        } catch (e) {
            console.log(e.message)
        }
    };

    const handleSelect = (eventKey) => {
        swapLanguage(eventKey);
        console.log("Header " + eventKey)
    };

    return (
        <Navbar className='navbarCustom ' expand="lg" sticky="top">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}<span className='brandText'>{text.Title}</span></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav className="me-auto">
                        <Nav.Link href="/">{text.Home}</Nav.Link>
                        <NavDropdown title={text.AboutDropdown} id="about">
                            <NavDropdown.Item href="/about">{text.About}</NavDropdown.Item>
                            <NavDropdown.Item href="/aboutTheDay">{text.AboutTheDay}</NavDropdown.Item>
                            <NavDropdown.Item href="/weddingParty">{text.WeddingParty}</NavDropdown.Item>
                            {/*<NavDropdown.Item href="/accommodation">{text.Accommodation}</NavDropdown.Item>*/}
                        </NavDropdown>
                        <Nav.Link href="/photoAlbum">{text.PhotoAlbum}</Nav.Link>
                        {/*<Nav.Link href="/music">{text.Music}</Nav.Link>*/}
                        <Nav.Link href="/firstDance">{text.Dance}</Nav.Link>
                        <Nav.Link href="/gift">{text.Gift}</Nav.Link>
                        <Nav.Link href="/FAQ">{text.FAQ}</Nav.Link>
                        <Nav.Link href="/contact">{text.Contact}</Nav.Link>
                        <NavDropdown title={user.displayName} id="profile">
                            <NavDropdown.Item href="/profile">{text.Profile}</NavDropdown.Item>
                            {/*<NavDropdown.Divider/>*/}
                            <NavDropdown.Item onClick={handleLogout}>{text.Logout}</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav className="me-auto" onSelect={handleSelect}>
                        <Nav.Link disabled>{text.Language} :</Nav.Link>
                        <Nav.Link eventKey="GB">
                            <img
                                alt=""
                                src={GBLogo}
                                width="30"
                                className="d-inline-block align-top"
                            />
                        </Nav.Link>
                        <Nav.Link eventKey="FR">
                            <img
                                alt=""
                                src={FRLogo}
                                width="30"
                                className="d-inline-block align-top"
                            />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
