import React, {useContext, useState} from "react";
import {Form, Table} from 'react-bootstrap';
import {useFirestore} from "../../Firebase/useFirestore";
import {addDoc, collection, doc, increment, serverTimestamp, updateDoc} from "firebase/firestore";
import {projectFirestore} from "../../Firebase/config";
import './Music.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import compareTwoStrings from '../../components/fucntions/compareTwoStrings';
import PopUp from "../../components/popUp/PopUp";
import LanguageContext from "../../components/Context/Context";
import GBMusic from './GBMusic';
import FRMusic from './FRMusic';
import {UserAuth} from "../../Firebase/auth";

function Music() {
    const {language} = useContext(LanguageContext);
    const {docs} = useFirestore('music', 'Vote');
    const [inputs, setInputs] = useState({});
    const [bestMatch, setBestMatch] = useState(null);
    const [popUp, setPopUp] = useState(false);
    const {user} = UserAuth();

    let music = language === 'GB' ? GBMusic : FRMusic;

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    };

    const togglePop = (match = 0) => {
        setPopUp(!popUp);
        setBestMatch(match)
    };

    const addMusic = (event) => {
        event.preventDefault();
        const createdAt = serverTimestamp();
        const collectionRef = collection(projectFirestore, 'music');
        const doc = {
            createdAt: createdAt,
            Artist: inputs.artist,
            SongTitle: inputs.songTitle,
            Vote: 0,
            Users: {}

        };
        if (inputs.artist && inputs.songTitle) {
            const match = checkMusic(inputs.artist, inputs.songTitle)
            if (typeof match === 'number') {
                togglePop(match)

            } else {
                addDoc(collectionRef, doc).then(() => {
                    setInputs("");
                })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }

    };

    const checkMusic = (artist, title) => {
        let bestMatch = {
            title : 0,
            artist: 0,
            idx: null
        }
        docs.forEach((doc, idx) => {
            let titleSimilarity, artistSimilarity = 0
            titleSimilarity = compareTwoStrings(title, doc.SongTitle);
            if (titleSimilarity >= 0.7 && titleSimilarity >= bestMatch.title) {
                bestMatch.title = titleSimilarity
                artistSimilarity = compareTwoStrings(artist, doc.Artist);
                if (artistSimilarity >= 0.7&& titleSimilarity >= bestMatch.artist) {
                    bestMatch.artist = artistSimilarity
                    bestMatch.idx = idx
                }
            }
        })

        return bestMatch.idx
    };

    const voteUp = (music, userVoted) => {
        if (userVoted !== "up") {
            const collectionRef = doc(projectFirestore, 'music', music.id);
            const users = "Users." + user.displayName;
            updateDoc(collectionRef, {
                Vote: increment(1),
                [users]: "up"
            });
        }
    };

    const voteDown = (music, userVoted) => {

        if (userVoted !== "down") {
            const collectionRef = doc(projectFirestore, 'music', music.id);
            const users = "Users." + user.displayName;
            updateDoc(collectionRef, {
                Vote: increment(-1),
                [users]: "down"

            });
        }
    };

    return (
        <div className="music">
            <div className="container">
                {popUp ?
                    <PopUp setPopUp={setPopUp} inputs={inputs} setInputs={setInputs} music={docs[bestMatch]}/> : null}
                <div className="row align-items-center my-5">
                    <div className="col-lg-7">
                        <h1 className="font-weight-light">{music.Title}</h1>
                        <p>
                            {music.P1}
                        </p>
                    </div>
                    <div>
                        <h3>{music.AddSong}</h3>
                        <Form>
                            <Row>
                                <Col className={'addSongForm'}>
                                    <Form.Control
                                        type="text"
                                        name="songTitle"
                                        value={inputs.songTitle || ""}
                                        onChange={handleChange}
                                        placeholder={music.Song}/>
                                </Col>
                                <Col className={'addSongForm'}>
                                    <Form.Control
                                        type="text"
                                        name="artist"
                                        value={inputs.artist || ""}
                                        onChange={handleChange}
                                        placeholder={music.Artist}/>
                                </Col>
                                <Col lg="2" className={'addSongButton'}>
                                    <button onClick={addMusic}>{music.Button}</button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div>
                        <h3>{music.First}</h3>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th className="col-lg-1">#</th>
                                <th className="col-lg-5">{music.Th1}</th>
                                <th className="col-lg-3">{music.Th2}</th>
                                <th className="col-lg-1">{music.Th3}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>Winter's Waltz</td>
                                <td>Christophe Beck</td>
                                <td>Not Votable</td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div>
                        <h3>{music.Evening}</h3>
                        <div className={'tbl-container'}>
                            <Table striped bordered hover className={'eTable'}>
                                <thead>
                                <tr>
                                    <th className="col-lg-1">#</th>
                                    <th className="col-lg-5">{music.Th1}</th>
                                    <th className="col-lg-3">{music.Th2}</th>
                                    <th className="col-lg-1">{music.Th3}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {docs && docs.map((music, idx) => (
                                    <tr key={idx}>
                                        <td>{idx}</td>
                                        <td>{music.SongTitle}</td>
                                        <td>{music.Artist}</td>
                                        <td>
                                            <button className={'vote left'}
                                                    onClick={() => voteUp(music, music.Users[user.displayName])}><i
                                                className={"fa fa-thumbs-up " + (music.Users[user.displayName] === "up" ? 'userUp' : 'null')}
                                                aria-hidden="true"/></button>
                                            <h4 className={'voteText'}>{music.Vote}</h4>
                                            <button className={'vote right'}
                                                    onClick={() => voteDown(music, music.Users[user.displayName])}><i
                                                className={"fa fa-thumbs-down " + (music.Users[user.displayName] === "down" ? 'userDown' : 'null')}
                                                aria-hidden="true"/></button>
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Music;
