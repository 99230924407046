import React from "react";
import {motion} from 'framer-motion';
import './Modal.css'

function Modal({selectedImage, setSelectedImg, docs}) {
    let currentSelectedImageIndex = 0;
    docs.forEach((doc, index) => {
        if (doc.url === selectedImage) {
            currentSelectedImageIndex = index
        }
    });
    const handleClick = (e) => {
        if (e.target.classList.contains('backdrop'))
            setSelectedImg(null)
    };

    const changeSelectedImage = (indexNumber) => {
        setSelectedImg(docs[indexNumber].url)
    };

    const previousImg = () => {
        changeSelectedImage(currentSelectedImageIndex - 1)
    };

    const nextImg = () => {
        changeSelectedImage(currentSelectedImageIndex + 1)
    };


    return (
        <div>
            <motion.div className="backdrop" onClick={handleClick}
                        initial={{opacity: 0}}
                        animate={{opacity: 1}}
            >
                <div className="backdrop-div">
                    {currentSelectedImageIndex !== 0 &&
                    <i className="fa-solid fa-circle-arrow-left backdrop-left" onClick={previousImg}/>}
                    <img src={selectedImage} alt="enlarged pic"/>
                    {currentSelectedImageIndex !== (docs.length - 1) &&
                    <i className="fa-solid fa-circle-arrow-right backdrop-right" onClick={nextImg}/>}
                </div>
            </motion.div>
        </div>
    );
}

export default Modal;
