import React, {useContext} from "react";
import AlbumGrid from "../../components/albumGrid/albumGrid";
import LanguageContext from "../../components/Context/Context";
import GBAlbum from './GBAlbum'
import FRAlbum from './FRAlbum'

function PhotoAlbum() {
    const {language} = useContext(LanguageContext);
    let album = language === 'GB' ? GBAlbum : FRAlbum;

    const albums = [
        // {
        //     album: 'background',
        //     name: 'Background Album'
        // },
        {
            album: 'engagement',
            name: album.Album1
        },
        {
            album: 'france2022',
            name: album.Album2
        },
        {
            album: 'engagementShoot',
            name: album.Album3
        },

        {
            album: 'granCanaria',
            name: album.Album4
        },

        {
            album: 'photoBooth',
            name: album.Album5
        },

        {
            album: 'SneakPeaks',
            name: album.Album6
        }
    ];
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-xs-5">
                        <h1 className="font-weight-light">{album.Title}</h1>
                        <p>{album.P1}<strong>{album.P1Strong}</strong></p>
                        <p>{album.P2} <a href="https://weduploader.com/upload/fGOsZ12e5u4TgXS9" target="_blank">{album.Link1}</a> </p>
                        <p>{album.P3} <a href="https://drive.google.com/drive/folders/1CXJqguUIlrhkMjd56HqKKhCRfIjSye15?usp=sharing" target="_blank">{album.Link2}</a></p>
                        <AlbumGrid albums={albums}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PhotoAlbum;
