import React, {useContext, useState} from "react";
import LanguageContext from "../../components/Context/Context";
import GBRSVP from './GBRSVPForm'
import FRRSVP from './FRRSVPForm'
import {Col, Form, InputGroup, Row} from "react-bootstrap";
import {getUsersProfile} from "../../Firebase/useFirestore";
import {UserAuth} from "../../Firebase/auth";
import './RSVPForm.css'
import {doc, updateDoc} from "firebase/firestore";
import {projectFirestore} from "../../Firebase/config";
import ProfileDataTable from "../ProfileData/ProfileDataTable";
import emailjs from 'emailjs-com';

function RSVPForm() {
    const {user} = UserAuth();
    const {language} = useContext(LanguageContext);
    const profile = getUsersProfile('profiles', user.email)
    const [inputs, setInputs] = useState({});
    let rsvp = language === 'GB' ? GBRSVP : FRRSVP;

    const SERVICE_ID = "service_k0dy9ki";
    const TEMPLATE_ID = "template_zgjcqss";
    const PUBLIC_KEY = "_rC8NPNs6GjFtiVLx";

    const handleChange = (event, value) => {
        const party = event.target.lang;
        const name = event.target.name;
        setInputs(values => ({...values, [party]: {...values[party], [name]: value}}));
    };

    const numberChange = (event, type) => {
        let {value, min, max} = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setInputs(values => ({...values, [type]: value}));
    };

    const textboxChange = (event) => {
        const value = event.target.value;
        handleChange(event, value)
    };

    const submitChoice = (event) => {
        const Party = getParty();
        event.preventDefault();
        const collectionRef = doc(projectFirestore, 'profiles', profile.id);
        updateDoc(collectionRef, {
            'RSVP Received': true,
            Party
        }).then(r => {
            email(Party)
        });
    };

    const email = (party) => {
        const email = {
            name: `RSVP Form reply from ${party[0].Name} `,
            message: JSON.stringify(party)
        };

        emailjs.send(SERVICE_ID, TEMPLATE_ID, email, PUBLIC_KEY)
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
            }, function (error) {
                console.log('FAILED...', error);
            });
    };


    const getParty = () => {
        let obj = {};
        if (profile.evening) {
            const p = profile.Party[0];
            obj[0] = {
                Name: p.Name,
                Surname: p.Surname,
                Attending: inputs[0].attending,
                Adult: inputs.Adult || '0',
                Kid: inputs.Kid || '0',
                Diet: inputs[0].diet || '-'
            }
        } else {
            for (const member in profile.Party) {
                const p = profile.Party[member]
                obj[member] = {
                    Name: p.Name,
                    Surname: p.Surname,
                    Kid: p.Kid || false,
                    Attending: inputs[member].attending,
                    Meal: inputs[member].meal || '-',
                    Dessert: inputs[member].dessert || '-',
                    Diet: inputs[member].diet || '-'
                }
            }
        }
        return obj
    };

    const attendingSelected = (party) => {
        if (inputs[party]) {
            return (inputs[party].attending === undefined || inputs[party].attending !== 'yes');
        }
        return true
    };

    const submitDisabled = () => {
        let disabled = false;
        if (profile.evening) {
            if (!disabled) {
                if (inputs[0]) {
                    if (inputs[0].attending === undefined || inputs[0].attending === 'no') {
                        disabled = true
                    } else if (inputs[0].attending === 'yes') {
                        disabled = inputs.Adult === undefined || inputs.Adult < 1;
                    }
                } else {
                    disabled = true
                }
            }
        } else {
            for (const member in profile.Party) {
                if (!disabled) {
                    if (inputs[member]) {
                        if (inputs[member].attending === undefined) {
                            disabled = true
                        } else if (inputs[member].attending === 'yes' && profile.Party[member].Kid) {
                            disabled = inputs[member].dessert === undefined;
                        } else if (inputs[member].attending === 'yes') {
                            disabled = inputs[member].meal === undefined;
                        }
                    } else {
                        disabled = true
                    }
                }
            }
        }
        return disabled
    };

    return (
        <div className="container">
            {profile &&
            <div>
                <Row className="row justify-content-around my-2">
                    {profile['RSVP Received'] ? (
                            <div>
                                <p className='thankYou'>{rsvp.ThankYou}</p>
                                <ProfileDataTable/>
                            </div>
                        ) :
                        (
                            <div>

                                <Row><h6 className=" font-weight-light reminder">{rsvp.Reminder}</h6></Row>
                                {
                                    profile.evening ? (
                                            <div className='RSVP'>
                                                <Form>
                                                    <Row>
                                                        <Col xs={12} sm={6} lg={4}>
                                                            <InputGroup className='name'>
                                                                <InputGroup.Text>{rsvp.Name}</InputGroup.Text>
                                                                <Form.Control
                                                                    className='RSVPinput'
                                                                    id="inlineFormInputGroup"
                                                                    placeholder={rsvp.Name}
                                                                    value={profile.Party[0].Name}
                                                                    readOnly/>
                                                            </InputGroup>
                                                        </Col>

                                                        <Col xs={12} sm={6} lg={4}>
                                                            <InputGroup>
                                                                <InputGroup.Text>{rsvp.Surname}</InputGroup.Text>
                                                                <Form.Control
                                                                    className='RSVPinput'
                                                                    id="inlineFormInputGroup"
                                                                    placeholder={rsvp.Surname}
                                                                    value={profile.Party[0].Surname}
                                                                    readOnly/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} md={2}>
                                                            <Form.Label className='rsvpLable'>
                                                                {rsvp.Attending}
                                                            </Form.Label>
                                                        </Col>
                                                        <Col xs={8} md={6} lg={3}
                                                             className='check'>
                                                            <Form.Check
                                                                className='check'
                                                                inline
                                                                type="radio"
                                                                label={rsvp.Yes}
                                                                name="attending"
                                                                id={`${0}yes`}
                                                                lang={0}
                                                                onChange={(e) => handleChange(e, 'yes')}
                                                            />
                                                        </Col>
                                                        <Col xs={4} lg={2}
                                                             className='check'>
                                                            <Form.Check
                                                                inline
                                                                type="radio"
                                                                label={rsvp.No}
                                                                name="attending"
                                                                id={`${0}no`}
                                                                lang={0}
                                                                onChange={(e) => handleChange(e, 'no')}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={3} lg={3}>
                                                            <InputGroup>
                                                                <InputGroup.Text>{rsvp.Adult}</InputGroup.Text>
                                                                <Form.Control className='RSVPnumber'
                                                                              type="number"
                                                                              name="adult"
                                                                              value={inputs.Adult || 0}
                                                                              min={0}
                                                                              max={profile.guest || 2}
                                                                              disabled={attendingSelected(0)}
                                                                              onChange={(e) => numberChange(e, 'Adult')}/>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col xs={3} lg={3}>
                                                            <InputGroup>
                                                                <InputGroup.Text>{rsvp.Kid}</InputGroup.Text>
                                                                <Form.Control className='RSVPnumber'
                                                                              type="number"
                                                                              name="kid"
                                                                              value={inputs.Kid || 0}
                                                                              min={0}
                                                                              max={6}
                                                                              disabled={attendingSelected(0)}
                                                                              onChange={(e) => numberChange(e, 'Kid')}/>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col xs={12} lg={6}>
                                                            <InputGroup>
                                                                <InputGroup.Text>{rsvp.Diet}</InputGroup.Text>
                                                                <Form.Control as="textarea"
                                                                              name="diet"
                                                                              aria-label="With textarea"
                                                                              lang={0}
                                                                              disabled={attendingSelected(0)}
                                                                              onChange={textboxChange}/>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>

                                            </div>
                                        ) :
                                        (
                                            Object.keys(profile.Party).map((party) => (
                                                <div key={party} className='RSVP'>
                                                    <Form>
                                                        <Row>
                                                            <Col xs={12} sm={6} lg={4}>
                                                                <InputGroup className='name'>
                                                                    <InputGroup.Text>{rsvp.Name}</InputGroup.Text>
                                                                    <Form.Control
                                                                        className='RSVPinput'
                                                                        id="inlineFormInputGroup"
                                                                        placeholder={rsvp.Name}
                                                                        value={profile.Party[party].Name}
                                                                        readOnly/>
                                                                </InputGroup>
                                                            </Col>

                                                            <Col xs={12} sm={6} lg={4}>
                                                                <InputGroup>
                                                                    <InputGroup.Text>{rsvp.Surname}</InputGroup.Text>
                                                                    <Form.Control
                                                                        className='RSVPinput'
                                                                        id="inlineFormInputGroup"
                                                                        placeholder={rsvp.Surname}
                                                                        value={profile.Party[party].Surname}
                                                                        readOnly/>
                                                                </InputGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={12} md={2}>
                                                                <Form.Label className='rsvpLable'>
                                                                    {rsvp.Attending}
                                                                </Form.Label>
                                                            </Col>
                                                            <Col xs={8} md={6} lg={3}
                                                                 className='check'>
                                                                <Form.Check
                                                                    className='check'
                                                                    inline
                                                                    type="radio"
                                                                    label={rsvp.Yes}
                                                                    name="attending"
                                                                    id={`${party}yes`}
                                                                    lang={party}
                                                                    onChange={(e) => handleChange(e, 'yes')}
                                                                />
                                                            </Col>
                                                            <Col xs={4} lg={2}
                                                                 className='check'>
                                                                <Form.Check
                                                                    inline
                                                                    type="radio"
                                                                    label={rsvp.No}
                                                                    name="attending"
                                                                    id={`${party}no`}
                                                                    lang={party}
                                                                    onChange={(e) => handleChange(e, 'no')}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        {profile.Party[party].Kid ?
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <Form.Label className='rsvpLable'>
                                                                        {rsvp.KidMeal}
                                                                    </Form.Label>
                                                                </Col>
                                                                <Col xs={12} md={3} lg={2}>
                                                                    <Form.Label className='rsvpLable'>
                                                                        {rsvp.KidMain}
                                                                    </Form.Label>
                                                                </Col>
                                                                <Col xs={6} md={4} lg={2}
                                                                     className='check'>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label={rsvp.Kid1}
                                                                        name="meal"
                                                                        id={`${party}Kid1`}
                                                                        lang={party}
                                                                        disabled={attendingSelected(party)}
                                                                        onChange={(e) => handleChange(e, 'Kid1')}
                                                                    />
                                                                </Col>
                                                                <Col xs={6} md={5} lg={2}
                                                                     className='check'>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label={rsvp.Kid2}
                                                                        name="meal"
                                                                        id={`${party}Kid2`}
                                                                        lang={party}
                                                                        disabled={attendingSelected(party)}
                                                                        onChange={(e) => handleChange(e, 'Kid2')}
                                                                    />
                                                                </Col>
                                                                <Col xs={6} md={4} lg={2}
                                                                     className='check'>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label={rsvp.Kid3}
                                                                        name="meal"
                                                                        id={`${party}Kid3`}
                                                                        lang={party}
                                                                        disabled={attendingSelected(party)}
                                                                        onChange={(e) => handleChange(e, 'Kid3')}
                                                                    />
                                                                </Col>
                                                                <Col xs={6} md={5} lg={2}
                                                                     className='check'>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label={rsvp.Kid4}
                                                                        name="meal"
                                                                        id={`${party}Kid4`}
                                                                        lang={party}
                                                                        disabled={attendingSelected(party)}
                                                                        onChange={(e) => handleChange(e, 'Kid4')}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            :
                                                            <Row>
                                                                <Col xs={12} md={3} lg={2}>
                                                                    <Form.Label className='rsvpLable'>
                                                                        {rsvp.Meal}
                                                                    </Form.Label>
                                                                </Col>
                                                                <Col xs={6} md={4} lg={2}
                                                                     className='check'>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label={rsvp.Meat}
                                                                        name="meal"
                                                                        id={`${party}meat`}
                                                                        lang={party}
                                                                        disabled={attendingSelected(party)}
                                                                        onChange={(e) => handleChange(e, 'meat')}
                                                                    />
                                                                </Col>
                                                                <Col xs={6} md={5} lg={2}
                                                                     className='check'>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label={rsvp.Veg}
                                                                        name="meal"
                                                                        id={`${party}veg`}
                                                                        lang={party}
                                                                        disabled={attendingSelected(party)}
                                                                        onChange={(e) => handleChange(e, 'veg')}
                                                                    />
                                                                </Col>
                                                                <Col xs={12} lg={6}>
                                                                    <InputGroup>
                                                                        <InputGroup.Text>{rsvp.Diet}</InputGroup.Text>
                                                                        <Form.Control as="textarea"
                                                                                      name="diet"
                                                                                      aria-label="With textarea"
                                                                                      lang={party}
                                                                                      disabled={attendingSelected(party)}
                                                                                      onChange={textboxChange}/>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        }
                                                        {profile.Party[party].Kid ?
                                                            <Row>
                                                                <Col xs={12} md={3} lg={2}>
                                                                    <Form.Label className='rsvpLable'>
                                                                        {rsvp.KidDesert}
                                                                    </Form.Label>
                                                                </Col>
                                                                <Col xs={6} md={4} lg={2}
                                                                     className='check'>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label={rsvp.Dessert1}
                                                                        name="dessert"
                                                                        id={`${party}Dessert1`}
                                                                        lang={party}
                                                                        disabled={attendingSelected(party)}
                                                                        onChange={(e) => handleChange(e, 'Dessert1')}
                                                                    />
                                                                </Col>
                                                                <Col xs={6} md={5} lg={2}
                                                                     className='check'>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label={rsvp.Dessert2}
                                                                        name="dessert"
                                                                        id={`${party}Dessert2`}
                                                                        lang={party}
                                                                        disabled={attendingSelected(party)}
                                                                        onChange={(e) => handleChange(e, 'Dessert2')}
                                                                    />
                                                                </Col>
                                                                <Col xs={6} md={4} lg={2}
                                                                     className='check'>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label={rsvp.Dessert3}
                                                                        name="dessert"
                                                                        id={`${party}Dessert3`}
                                                                        lang={party}
                                                                        disabled={attendingSelected(party)}
                                                                        onChange={(e) => handleChange(e, 'Dessert3')}
                                                                    />
                                                                </Col>
                                                                <Col xs={6} md={5} lg={2}
                                                                     className='check'>
                                                                    <Form.Check
                                                                        inline
                                                                        type="radio"
                                                                        label={rsvp.Dessert4}
                                                                        name="dessert"
                                                                        id={`${party}Dessert4`}
                                                                        lang={party}
                                                                        disabled={attendingSelected(party)}
                                                                        onChange={(e) => handleChange(e, 'Dessert4')}
                                                                    />
                                                                </Col>
                                                                <Col xs={12} lg={6}>
                                                                    <InputGroup>
                                                                        <InputGroup.Text>{rsvp.Diet}</InputGroup.Text>
                                                                        <Form.Control as="textarea"
                                                                                      name="diet"
                                                                                      aria-label="With textarea"
                                                                                      lang={party}
                                                                                      disabled={attendingSelected(party)}
                                                                                      onChange={textboxChange}/>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row> :
                                                            <Row/>
                                                        }
                                                    </Form>

                                                </div>
                                            ))
                                        )
                                }
                                <Row className="justify-content-md-center">
                                    <Col xs={1}>
                                        <button onClick={submitChoice}
                                                disabled={submitDisabled()}
                                        >Submit
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Row>
            </div>
            }
        </div>
    );
}

export default RSVPForm;
